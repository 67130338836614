import React, { Component } from "react";
import { graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Seo from "../components/Seo/Seo";
import Posts from "../components/Posts";

export default class Tag extends Component {
  render() {
    const { data, pageContext } = this.props;
    const { tag } = pageContext;
    const { pathname } = this.props.location;
    return (
      <section className="hero">
        <div className="hero-head header-tag">
          <span className="btn-home icon is-medium has-text-white">
            <a href="/" title="Voltar para a home">
              <FontAwesomeIcon
                size="2x"
                style={{ color: "#fff" }}
                icon={faHome}
              />
            </a>
          </span>
          <Seo
            title={`Tag: ${tag}`}
            description={`Todos os posts marcados com a tag "${tag}" no CodeStack Brasil`}
            url={pathname}
          />
        </div>
        <h2 style={{ fontSize: "22px", padding: "0px 15px" }}>
          Todos os posts com a tag: {tag}
        </h2>
        <Posts data={data} />
      </section>
    );
  }
}

export const pageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___prefix], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, draft: { ne: true } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD/MM/YYYY")
            description
            tags
            image
          }
        }
      }
    }
  }
`;
